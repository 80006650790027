/* global OktaSignIn */
import "@/styles/app.scss";
import $ from "jquery";

$(function () {
  // Run on every page
  $('[data-toggle="tooltip"]').tooltip();

  // Switch favicon based on light/dark mode
  function onColorSchemeChange(query) {
    const icons = document.querySelectorAll("link[rel~='icon']");
    if (query.matches) {
      for (const link of icons) {
        link.href = link.href.replace("favicon.", "favicon-dark.");
      }
    } else {
      for (const link of icons) {
        link.href = link.href.replace("favicon-dark.", "favicon.");
      }
    }
  }
  const match = window.matchMedia("(prefers-color-scheme: dark)");
  match.addEventListener("change", onColorSchemeChange);
  onColorSchemeChange(match); // Initial State
});

window.handleAppLogout = function (redirectUri) {
  const oktaConfig = JSON.parse(document.getElementById("okta-widget-config").textContent);
  const oktaSignIn = new OktaSignIn(oktaConfig);
  oktaSignIn.authClient.signOut({
    clearTokensBeforeRedirect: true,
    postLogoutRedirectUri: redirectUri,
  });
};
